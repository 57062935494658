.ant-tabs .ant-tabs-nav {
  margin: 0 !important;
  background: white;
}

.ant-tabs .ant-tabs-tab {
  color: var(--Colors-Neutral-Text-colorTextSecondary, #000000a6);
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: black !important;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn svg {
  color: black !important;
}

.ant-tabs .ant-tabs-tab:hover {
  color: purple !important;
}

.ant-tabs .ant-tabs-nav-wrap {
  margin-left: 20px;
}

.ant-tabs .ant-tabs-ink-bar {
  background: purple !important;
}

.disabled-tab {
  cursor: not-allowed;
  opacity: 0.6;
  position: relative;
}

.disabled-tab::after {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap;
}

.disabled-tab:hover::after {
  opacity: 1;
}
