.wrapper {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1000;
}

.wrapper .backdrop {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 248, 248, 0.3);
    opacity: 1;
    z-index: 0;
}

.wrapper .preLoader {
    position: relative;
    color: #fff;
    text-align: center;
    z-index: 10;
}

.wrapper .preLoader span {
    display: flex;
    margin: auto;
}
