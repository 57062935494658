.application-container {
  background-color: white;
  border: #d9d9d9;
  border-radius: 12px;
  width: auto;
  /* height: calc(100vh - 30px); */
  color: black;
}

.disabled-card {
  cursor: not-allowed;
  opacity: 0.6;
  position: relative;
}

.disabled-card .coming-soon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s;
}

.disabled-card:hover .coming-soon {
  opacity: 1;
}

