@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  background-color: #f8f8f8 !important;
  min-height: 100vh;
  /* color: white; */
  color: black;

  &::-webkit-scrollbar {
    height: 4px !important;
    width: 6px !important;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 15px;
  }
}

.ant-btn-default {
  &:hover {
    background-color: #a90083 !important;
    color: white !important;
    border-color: #a90083 !important;
  }
}

.ant-btn-primary {
  background-color: #a90083 !important;
}

.ant-modal-header {
  border-bottom: 1px solid black !important;
}

.ant-modal-title {
  padding-bottom: 8px !important;
}

.custom-checkbox .ant-checkbox-checked span.ant-checkbox-inner {
  background-color: #a90083 !important;
  border-color: #a90083 !important;
}
/* .custom-checkbox .ant-checkbox-checked .ant-checkbox-input:hover {
  background-color: #a90083 !important;
  border-color: #a90083 !important;
} */

.custom-checkbox .ant-checkbox-checked span.ant-checkbox-inner:hover {
  background-color: #a90083 !important;
  border-color: #a90083 !important;
}

.ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner:hover{
  background-color: #a90083 !important;
  border-color: #a90083 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner:after{
  border-color: #ffffff !important;
}